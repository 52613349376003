import React, { Suspense, useState, useEffect, lazy } from 'react';
import {TalentCard} from './types';
import { useTranslation } from 'react-i18next';


const TokenButtonLazy = lazy(() => import('./TokenButton'));
const CustomTooltipLazy = lazy(() => import('./toolTip'));

interface ExperienceDisplayProps {
  card: TalentCard;
  locationXP: string;
  activeSection: string;
}

const ExperienceDisplay: React.FC<ExperienceDisplayProps> = ({ card, locationXP, activeSection}) => {
  const [experience, setExperience] = useState<string | null>(null);
  const [force, setForce] = useState<string | null>(null); 
  const [score, setScore] = useState<string | null>(null); 
  const [gameWeek, setGameWeek] = useState<string | null>(null); 
  const [cardXpBonus, setCardXpBonus] = useState<string | null>(null); 
  const [cardForceBonus, setCardForceBonus] = useState<string | null>(null); 
  const [similarCardsBonus, setSimilarCardsBonus] = useState<string | null>(null); 
  const [finalScore, setFinalScore] = useState<string | null>(null); 
  const { t } = useTranslation();

  const shouldShowTokenButton = (locationXP.includes('competition') || locationXP.includes('team') || locationXP.includes('/insights'));
  const [isScoreDisplayVisible, setIsScoreDisplayVisible] = useState(false);

  const toggleScoreDisplay = () => {
    setIsScoreDisplayVisible(!isScoreDisplayVisible);
  };


  useEffect(() => {
    const fetchData = async () => {
      try {

        setExperience(card.card_experience.toString() || 'Not available'); // Adjust according to the actual response structure
        setForce(card.card_force || 'Not available'); // Adjust according to the actual response structure
        setScore(card.current_score || 'Not available');
        setGameWeek(card.current_game || 'Not available');
        setCardXpBonus(card.card_xp_bonus || 'Not available');
        setCardForceBonus(card.card_force_bonus || 'Not available');
        setSimilarCardsBonus(card.similar_cards_bonus || 'Not available');
        setFinalScore(card.final_score || 'Not available');
      } catch (error) {
        console.error("Error fetching data: ", error);
        setExperience('Error fetching experience.');
        setForce('Error fetching force.');
      }
    };

    fetchData();
  }, [card]); // Dependencies array, re-fetch if these values change

  const token = {
    card: card,
  };

  let classNameXP = '';
  let classNameGame = '';

  console.log('locxp', locationXP);
  console.log('activeSection', activeSection);

  
    if (activeSection === 'myLineup') {
      classNameXP = 'general_tabs_center';
    } else {
      classNameXP = 'general_tabs';
    }
 

  if (activeSection === 'myLineup' || locationXP.includes('competition')) {
    classNameGame = 'gaming_experience_score';
  } else {
    classNameGame = 'no_score';
  }

  const NumberFormatter = ({ number }: { number: string }) => {
    // Convert string to number and format with spaces instead of commas
    const formattedNumber = Number(number).toLocaleString().replace(/,/g, ' ');
  
    return <span>{formattedNumber}</span>;
  };


  const isMobile = window.innerWidth < 768;



  return (
    <div className="gaming_experience">
    <span className={classNameXP}>
    <span className="force_market">
      <i 
        style={{ 
          fontSize: '70%', 
          position: 'relative', 
          top: '-1px' 
        }}  
        className="fa-solid fa-shield-halved"
      ></i>&nbsp;
      {force}
    </span>
    <span className="xp_market">
      <i 
        style={{ 
          fontSize: '70%', 
          position: 'relative', 
          top: '-1px' 
        }}  
        className="fa-solid fa-bolt"
      ></i>&nbsp;
      {experience}
    </span>
    {shouldShowTokenButton && (
    <Suspense fallback={<div>Loading...</div>}>
          <TokenButtonLazy 
          card={card}
           /></Suspense>
    
      )}
    </span>

{activeSection === 'myLineup' && (
  <>
<br/>

<span className="gaming_score_display">

<i className="fa-solid fa-square-poll-vertical" style={{fontSize: '80%'}}></i>&nbsp;
<NumberFormatter number={finalScore || '0'} />&nbsp;
<button className="button_tooltip" onClick={toggleScoreDisplay}>
        <i className="fa-solid fa-circle-info" style={{ fontSize: '70%', color: '#4c550a' }}></i>
      </button>
</span> 

{isScoreDisplayVisible && (
<div id="gaming_score_display_mobile" style={{fontSize:'75%'}}>

<div className={classNameGame}>
   
    <span className="gaming_row">
    <span className="gaming_experience_score_text">{t('performance')}</span>
    <span className="gaming_experience_score_value">{score}</span>
    </span>
    <span className="gaming_row">
    <span className="gaming_experience_score_text">{t('bonusForce')}</span>
    <span className="gaming_experience_score_value">{cardForceBonus}</span>
    </span>
    <span className="gaming_row">
    <span className="gaming_experience_score_text">{t('bonusExperience')}</span>
    <span className="gaming_experience_score_value">{cardXpBonus}</span>
    </span>
    <span className="gaming_row">
    <span className="gaming_experience_score_text">{t('bonusCards')}</span>
    <span className="gaming_experience_score_value">{similarCardsBonus}</span>
    </span>
    <span className="gaming_row">
    <span className="gaming_experience_score_text_final">{t('currentScore')}</span>
    <span className="gaming_experience_score_value_final">{finalScore}</span>
    </span>

    </div>

</div>
      )}
















</>
)}

    </div>
  );
};

export default ExperienceDisplay;
